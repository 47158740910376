import React, { useContext } from "react";
import { Stack, Main, Sidebar } from "@layout";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import { Button, Card } from "theme-ui";
import ContactInfo from "@widgets/ContactInfo";
import NewsletterExpanded from "@widgets/NewsletterExpanded";
import LoginCard from "@widgets/LoginCard";
import { navigate } from "gatsby";
import { logout, AuthContext } from "@elegantstack/core-blog/src/context/auth";
import LoginDialog from "@widgets/LoginDialog";

export default ({ ...props }) => {
  const { pageContext: { services = {} } = {} } = props;
  const { user } = useContext(AuthContext);

  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <>
      <Seo title="Join now" />
      <Divider />
      <Stack>
        <Main>
          <LoginCard />
          <LoginDialog
            open={open}
            onClose={() => {
              handleClose();
            }}
          ></LoginDialog>
        </Main>
      </Stack>
    </>
  );
};
